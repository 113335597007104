<template>
    <div class="helperCenter">
        <p v-for="(im,ix) in helpList" :key="ix" class="flex alignCenter marginAuto">
            <span class="cursorP spanhover" @click="getDetail(im.id)">{{im.title}}</span>
        </p>
        <el-dialog
        :title="popupData.title"
        :visible.sync="dialogVisible"
        width="60%">
        <!-- <span>{{popupData.ctime}}</span> -->
        <div class="noscroll" style="height:400px;overflow:auto;margin-top:30px;">
            <div v-html="popupData.content"></div>
        </div>
        </el-dialog>
    </div>
</template>
<script>
import { help_list } from '@/utils/Api/userList'
export default {
    name:'helperCenter',
    data(){
        return{
            helpList:[],
            dialogVisible: false,
            popupData:{}
        }
    },
    created(){
        this.initial()
    },
    methods:{
        async initial(params){
            let res = await help_list(params)
            if(res){
                this.helpList = res
            }
        },
        async getDetail(id){
            let res = await help_list({help_id: id})
            if(res){
                this.popupData = res
                this.dialogVisible = true
                return
            }
            this.$message.error('数据走丢啦 ~')
        }
    }
}
</script>
<style lang="scss" scoped>
.spanhover:hover{color: #47D7E3;}
.helperCenter{
    p{
        width: 849px;
        height: 71px;
        border-bottom: 1px solid #e8e8e8;    
        font-size: 15px;
        color: #333333;
        padding-left: 20px;
    }
}
</style>